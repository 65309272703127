.App {
  max-width: 800px;
  margin: auto;
  padding: 5rem 2rem;
  line-height: 1.4em;
}

h1 {
  font-weight: bold;
}

em {
  font-style: italic;
}

p {
  margin-top: 2rem;
}

.nowrap {
  white-space: nowrap;
}

textarea {
  -webkit-appearance: none;
  padding: 0 0.2rem;
  width: auto;
  height: auto;
  border: none;
  border-radius: 0;
  background: #FEF9FF;
  font: inherit;
  width: 100%;
  height: 400px;
}

select {
  -webkit-appearance: none;
  padding: 0 0.2rem;
  width: auto;
  height: auto;
  border: none;
  border-bottom: 2px dashed #d4c1ec;
  border-radius: 0;
  color: inherit;
  background: #FEF9FF;
  font: inherit;
  cursor: pointer;
}

select:hover {
  background-color: #d4c1ec;
}

option {
  max-width: 100%;
  overflow: hidden;
  word-wrap: normal !important;
  white-space: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button {
  -webkit-appearance: none;
  background-color: #736CED;
  color: #fff;
  border: none;
  font: inherit;
  padding: 1rem;
  width: 100%;
  white-space: nowrap;
}

button:hover {
  background-color: #9F9FED;
}

button:active {
  background-color: #D4C1EC;
}

.toolbar {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.toolbar a {
  display: block;
}

.toolbar * {
  flex: 1;
}

.response {
  margin-top: 4rem;
  background: #F2DFD7;
  padding: 1rem;
  white-space: pre-wrap;
}

.response * {
  white-space: pre-wrap;
}

@keyframes grow-shrink {
  0% {
    transform: rotate(0deg) scale(1);
  }

  1% {
    transform: rotate(2.5deg) scale(1.05);
  }

  3% {
    transform: rotate(-2.5deg) scale(1.05);
  }

  5% {
    transform: rotate(2.5deg) scale(1.05);
  }

  7% {
    transform: rotate(-2.5deg) scale(1.05);
  }

  9% {
    transform: rotate(0deg) scale(1);
  }
}

.warning {
  margin-top: 1rem;
  padding: 1rem;
  background: #D4C1EC;
  font-weight: bold;
  animation: grow-shrink 5s infinite;
}

summary {
  cursor: pointer;
  margin-top: 2rem;
}

.prewrap {
  white-space: pre-wrap;
}